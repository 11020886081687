<template>
  <v-container>
    <v-row class="grey darken-3 pa-3">
      <v-col cols="5">
        <h4 class="white--text text-subtitle-1 text-uppercase">{{ 'summary'|localize }}:</h4>
      </v-col>
      <v-col cols="3">
        <h4 class="white--text text-subtitle-1">{{ 'totalOfPackages'|localize }}, {{ totalPackages }}</h4>
      </v-col>
      <v-col cols="2">
        <h4 class="white--text text-subtitle-1">{{ 'weight'|localize }}, {{ totalWeight }} kg</h4>
      </v-col>
      <v-col cols="2">
        <h4 class="white--text text-subtitle-1">{{ 'volume'|localize }}, {{ totalVolume }} m<sup>3</sup></h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'GoodsSummary',
    props: ['totalPackages', 'totalWeight', 'totalVolume'],
  }
</script>