<template>
  <v-card class="mb-3" outlined color="#f3f3f3" style="border-color: #E4E4E4">
    <v-row class="pa-3" justify="space-between">
      <v-col cols="1">
        <div class="ma-2 number-element text-h6">{{ number + 1 }}</div>
      </v-col>
      <v-col cols="4">
        <h6 class="text-subtitle-1">{{ 'typeOfGoods'|localize }} *</h6>
        <v-select
          v-model="type_of_goods"
          :items="goodTypes"
          class="mt-2"
          item-text="value"
          item-value="key"
          item-color="#808080"
          color="#808080"
          dense
          outlined
          :rules="[validationRules.required_goods]"
          :disabled="disabled"
          :background-color="disabled ? '#f3f3f3' : 'white'"
          :append-icon="disabled ? 'mdi-lock' : 'mdi-menu-down'"
          :no-data-text="'noDataAvailable'|localize"
        />
        <template v-if="type_of_goods">
          <v-checkbox
            v-if="(!isPriceRequest && isPallet) || (isPriceRequest && isPallet && isDimensions)"
            v-model="is_stackable"
            color="primary"
            :disabled="disabled"
            class="mt-2"
          >
            <h5 slot="label" class="text-body-1">{{ 'stackable'|localize }}</h5>
          </v-checkbox>
          <v-radio-group
            v-model="request_dimensions"
            v-if="isPriceRequest && (isPallet || isPackage)"
            :disabled="disabled || limited"
          >
            <v-radio value="dimensions" :color="disabled || limited ? '#DADADA' : 'primary'">
              <template v-slot:label>
                <span class="black--text">{{ 'goodsHaveDimensions'|localize }}</span>
              </template>
            </v-radio>
            <v-radio value="volume" :color="disabled || limited ? '#DADADA' : 'primary'">
              <template v-slot:label>
                <span class="black--text">{{ 'onlyTotalVolume'|localize }}</span>
              </template>
            </v-radio>
            <v-radio value="meters" :color="disabled || limited ? '#DADADA' : 'primary'">
              <template v-slot:label>
                <span class="black--text">{{ 'onlyTotalMeters'|localize }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </template>
      </v-col>
      <v-col cols="6">
        <template v-if="type_of_goods">
          <v-row>
            <v-col cols="3">
              <h6 class="text-subtitle-1">
                {{ 'weight'|localize }} (kg)
                <template v-if="!isContainers">*</template>
              </h6>
              <v-text-field
                :disabled="disabled"
                :background-color="disabled ? '#f3f3f3' : 'white'"
                :append-icon="disabled ? 'mdi-lock' : ''"
                class="mt-2 centered-input goods-parameters"
                dense
                outlined
                v-model.number="weight"
                maxlength="5"
                :counter="disabled ? null : '5'"
                :rules="isContainers ?
                  [validationRules.integer] :
                  [validationRules.required_goods, validationRules.integer, validationRules.required_not_zero]
                "
              />
            </v-col>
            <template v-if="goodsDimensions">
              <v-col cols="3">
                <h6 class="text-subtitle-1">{{ 'length'|localize }} (cm) *</h6>
                <v-text-field
                  :disabled="disabled"
                  :background-color="disabled ? '#f3f3f3' : 'white'"
                  :append-icon="disabled ? 'mdi-lock' : ''"
                  class="mt-2 centered-input goods-parameters"
                  dense
                  outlined
                  v-model.number="length"
                  maxlength="4"
                  :counter="disabled ? null : '4'"
                  :rules="[validationRules.required_goods, validationRules.integer, validationRules.required_not_zero]"
                />
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1">{{ 'width'|localize }} (cm) *</h6>
                <v-text-field
                  :disabled="disabled"
                  :background-color="disabled ? '#f3f3f3' : 'white'"
                  :append-icon="disabled ? 'mdi-lock' : ''"
                  class="mt-2 centered-input goods-parameters"
                  dense
                  outlined
                  v-model.number="width"
                  maxlength="4"
                  :counter="disabled ? null : '4'"
                  :rules="[validationRules.required_goods, validationRules.integer, validationRules.required_not_zero]"
                />
              </v-col>
              <v-col cols="3">
                <h6 class="text-subtitle-1">{{ 'height'|localize }} (cm) *</h6>
                <v-text-field
                  :disabled="disabled"
                  :background-color="disabled ? '#f3f3f3' : 'white'"
                  :append-icon="disabled ? 'mdi-lock' : ''"
                  class="mt-2 centered-input goods-parameters"
                  dense
                  outlined
                  v-model.number="height"
                  maxlength="4"
                  :counter="disabled ? null : '4'"
                  :rules="[validationRules.required_goods, validationRules.integer, validationRules.required_not_zero]"
                />
              </v-col>
            </template>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="6" v-if="isShowLoadingMeters">
              <h6 class="text-subtitle-1">
                {{ 'loadingMeters'|localize }}, m
              </h6>
              <v-text-field
                v-if="editableLoadingMeters"
                class="mt-2 centered-input"
                dense
                outlined
                :placeholder="'loadingMeters'|localize"
                :disabled="disabled || limited"
                :background-color="disabled || limited ? '#f3f3f3' : 'white'"
                :append-icon="disabled || limited ? 'mdi-lock' : ''"
                v-model.number="loading_meters"
                :rules="[validationRules.required_goods, validationRules.number]"
              />
              <span v-else>
                {{ loading_meters ? loading_meters : '0' }}
              </span>
            </v-col>
            <v-col cols="6" v-if="goodsDimensions">
              <h6 class="text-subtitle-1">{{ 'volume'|localize }} (m<sup>3</sup>)</h6>
              <span>
                {{ volume ? volume : '0' }}
              </span>
            </v-col>
            <v-col cols="6" v-if="editableVolume">
              <h6 class="text-subtitle-1">{{ 'volume'|localize }} (m<sup>3</sup>)</h6>
              <v-text-field
                class="mt-2 centered-input"
                outlined
                dense
                :placeholder="'totalVolume' | localize"
                :background-color="disabled || limited ? '#f3f3f3' : 'white'"
                :disabled="disabled || limited"
                :append-icon="disabled || limited ? 'mdi-lock' : ''"
                :rules="[validationRules.required_goods, validationRules.number]"
                v-model.number="volume"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col v-if="!disabled" cols="auto" class="d-flex flex-column primary justify-center">
        <v-btn icon class="ma-2 mb-4" color="white" @click="deleteItem(number)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon class="ma-2 mt-4" color="white" @click="copyItem(currentItem)">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import validationRules from '../helpers/validationRules';
  import {TRANSPORT_TYPES} from '@/enums/transportMode';
  import {REQUEST_TYPES} from '@/enums/requestType';
  import {GOOD_TYPES} from '@/enums/goodTypes';

  export default {
    name: 'GoodComponent',
    props: ['currentItem', 'number', 'goodTypes', 'requestType', 'transportMode', 'disabled', 'limited'],

    data() {
      return {
        REQUEST_TYPES,
        GOOD_TYPES,
        TRANSPORT_TYPES,
        validationRules: validationRules,
      };
    },

  watch: {
    requestType() {
      if (this.limited) {
        this.calculateVolume();
      }
    },
    type_of_goods() {
      this.resetParameters();
    },
    is_stackable() {
      this.calculateLoadingMeters();
    },
    request_dimensions() {
      this.resetParameters();
    },
    length() {
      this.calculateVolume();
      this.calculateLoadingMeters();
    },
    width() {
      this.calculateVolume();
      this.calculateLoadingMeters();
    },
    height() {
      this.calculateVolume();
    }
  },

  computed: {
    type_of_goods: {
      get() {
        return this.currentItem.type_of_goods;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'type_of_goods', value: newValue});
      },
    },
    weight: {
      get() {
        return this.currentItem.weight;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'weight', value: newValue});
      },
    },
    length: {
      get() {
        return this.currentItem.length;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'length', value: newValue});
      },
    },
    width: {
      get() {
        return this.currentItem.width;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'width', value: newValue});
      },
    },
    height: {
      get() {
        return this.currentItem.height;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'height', value: newValue});
      },
    },
    is_stackable: {
      get() {
        return this.currentItem.is_stackable;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'is_stackable', value: newValue});
      },
    },
    request_dimensions: {
      get() {
        return this.currentItem.request_dimensions;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'request_dimensions', value: newValue});
      },
    },
    volume: {
      get() {
        return this.currentItem.volume;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'volume', value: newValue});
      },
    },
    loading_meters: {
      get() {
        return this.currentItem.loading_meters;
      },
      set(newValue) {
        this.$emit('updateItem', {name: 'loading_meters', value: newValue});
      },
    },
    isPriceRequest() {
      return this.requestType === REQUEST_TYPES.PRICE_REQUEST
    },
    isPallet() {
      return this.type_of_goods === GOOD_TYPES.PALLET
    },
    isPackage() {
      return this.type_of_goods === GOOD_TYPES.PACKAGE
    },
    isRoad() {
      return this.transportMode.includes(TRANSPORT_TYPES.ROAD)
    },
    isFTL() {
      return this.type_of_goods === GOOD_TYPES.FTL;
    },
    isContainers() {
      return (
        this.type_of_goods === GOOD_TYPES['40_HC'] ||
        this.type_of_goods === GOOD_TYPES['40'] ||
        this.type_of_goods === GOOD_TYPES['20']
      );
    },
    isDimensions() {
      return this.request_dimensions === 'dimensions'
    },
    isVolume() {
      return this.request_dimensions === 'volume'
    },
    isMeters() {
      return this.request_dimensions === 'meters'
    },
    editableLoadingMeters() {
      return (
        this.isPriceRequest && (
          (this.isPallet && this.isMeters) ||
          (this.isPackage && this.isMeters)
        )
      )
    },
    isShowLoadingMeters() {
      return (
        (this.isPriceRequest && this.isPallet && this.isMeters) ||
        (this.isPriceRequest && this.isPallet && this.isDimensions && !this.is_stackable) ||
        (this.isPriceRequest && this.isPackage && this.isMeters) ||
        (!this.isPriceRequest && this.isPallet && !this.is_stackable)
      );
    },
    goodsDimensions() {
      return (
        (!this.isPriceRequest && this.isPallet) ||
        (!this.isPriceRequest && this.isPackage) ||
        (this.isPriceRequest && this.isDimensions && !this.isContainers && !this.isFTL)
      );
    },
    editableVolume() {
      return (
        this.isPriceRequest && (
        (this.isVolume && this.isRoad) || (!this.isContainers && this.isVolume)
      ));
    },
  },
  methods: {
    calculateLoadingMeters() {
      let loadingMeters = (!this.is_stackable) ? ((this.length * this.width) / 24000).toFixed(4) : 0;
      loadingMeters = isNaN(loadingMeters) ? 0 : loadingMeters;
      if (this.limited) {
        if (!this.editableLoadingMeters) {
          this.$emit('updateItem', {name: 'loading_meters', value: loadingMeters});
        }
      } else {
        this.$emit('updateItem', {name: 'loading_meters', value: loadingMeters});
      }
    },
    calculateVolume() {
      let volume = parseFloat(((this.length * this.width * this.height) / 1000000).toFixed(3));
      volume = isNaN(volume) ? 0 : volume;
      if (this.limited) {
        if (!this.editableVolume) {
          this.$emit('updateItem', {name: 'volume', value: volume});
        }
      } else {
        this.$emit('updateItem', {name: 'volume', value: volume});
      }
    },
    resetParameters() {
      if (this.limited) {
        if (!this.goodsDimensions) {
          this.length = '';
          this.width = '';
          this.height = '';
        }
      } else {
        this.weight = '';
        this.length = '';
        this.width = '';
        this.height = '';
        this.volume = '';
        this.loading_meters = '';
      }
    },
    copyItem(item) {
      this.$emit('copyItem', item);
    },
    deleteItem(index) {
      this.$emit('deleteItem', index);
    },
  }
  };
</script>

<style scoped lang="scss">
  .number-element {
    width: 48px;
    height: 48px;
    border: 2px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
